import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import './SplitSectionLeftRight.css';

type SplitSectionLeftRightProps = {
  backgroundImage: string;
  leftImage: string;
  middleText: string;
  leftButtons: React.ReactNode;
  rightImage: string;
};

const SplitSectionLeftRight: React.FC<SplitSectionLeftRightProps> = ({ backgroundImage, leftImage, middleText, leftButtons, rightImage }) => {
  return (
    <Box
      className="split-section"
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
        padding: '40px 5%', // Añadir padding para separación
      }}
    >
      {/* Sección Izquierda con Imagen, Texto y Botones */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '50%',
          color: 'white',
          paddingRight: '5%', // Añadir separación del lado derecho
        }}
      >
        {/* Imagen superior */}
        <Box sx={{ width: '50%', marginBottom: '20px' }}>
          <img src={leftImage} alt="Left Image" style={{ width: '100%', height: 'auto', borderRadius: '12px' }} />
        </Box>

        {/* Título y descripción */}
        <Typography variant="h4" sx={{ fontFamily: 'Codec Pro', fontWeight: '800', fontSize: '2.5rem', marginBottom: '0.5rem' }}>
          Software
        </Typography>
        <Typography variant="body1" sx={{ fontFamily: 'Arimo', fontSize: '1.5rem', marginBottom: '1.5rem' }}>
          salón de belleza-spa-barbería y peluquería
        </Typography>

        {/* Botones con funcionalidad */}
        <Box sx={{ display: 'flex', gap: '10px' }}>
          {/* Botón para WhatsApp */}
          <Button
            variant="contained"
            sx={{
              fontFamily: 'Codec Pro',
              fontWeight: '800',
              backgroundColor: '#D8D8D8',
              color: '#2E1A47',
              borderRadius: '24px',
              padding: '10px 20px',
              textTransform: 'none',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', // Sombra difuminada
              '&:hover': {
                backgroundColor: '#cfcfcf',
              },
            }}
            onClick={() => {
              window.open(
                `https://wa.me/59162792666?text=Hola,%20estoy%20interesado%20en%20probar%20el%20software%20para%20mi%20salón.%20¿Podrían%20darme%20más%20información?`,
                '_blank'
              );
            }}
          >
            Prueba gratis
          </Button>

          {/* Botón para redirigir al subdominio */}
          <Button
              variant="contained"
              sx={{
                fontFamily: 'Codec Pro',
                fontWeight: '800',
                backgroundColor: '#D8D8D8',
                color: '#2E1A47',
                borderRadius: '24px',
                padding: '10px 20px',
                textTransform: 'none',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', // Sombra difuminada
                '&:hover': {
                  backgroundColor: '#cfcfcf',
                },
              }}
              onClick={() => {
                window.open('https://urustyle.novaasesores.com', '_blank');
              }}
            >
              Entrar
          </Button>

        </Box>
      </Box>

      {/* Sección Derecha con Imagen */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '50%',
          maxWidth: '400px', // Limita el ancho de la imagen
        }}
      >
        <img
          src={rightImage}
          alt="Right Image"
          style={{
            width: '100%',
            height: 'auto',
            borderRadius: '16px', // Bordes redondeados en la imagen
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Sombra para dar profundidad
          }}
        />
      </Box>
    </Box>
  );
};

export default SplitSectionLeftRight;
